import { css } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { StructuredText } from 'react-datocms'

import HeroImage from '../components/HeroImage'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { allCaps, baseGrid, mq } from '../theme/mixins'

const AboutPage = () => {
  const { page } = useStaticQuery(graphql`
    query {
      page: datoCmsAboutPage {
        heroImage {
          gatsbyImageData(imgixParams: { q: 75 })
          alt
          focalPoint {
            x
            y
          }
        }
        heading
        ledeNode {
          childMarkdownRemark {
            html
          }
        }
        body {
          value
          blocks {
            __typename
            id: originalId
            image {
              gatsbyImageData(imgixParams: { q: 75 })
              alt
              title
            }
          }
        }
        seo {
          title
          description
        }
      }
    }
  `)

  const mainStyle = css`
    ${baseGrid};
    --imageHeight: max(37.5vw, 50vh);
    grid-template-rows:
      calc(var(--imageHeight) - var(--gutter-lg))
      var(--gutter-lg) auto;
    margin-bottom: var(--gutter-xlg);
  `
  const heroImageStyle = css`
    grid-row: 1 / 3;
  `
  const heroImageInnerStyle = css`
    height: calc(var(--imageHeight) + var(--nav-menu-height));
  `
  const bodyStyle = css`
    grid-column: 1 / -1;
    grid-row: 2 / 4;
    ${baseGrid}
    padding-bottom: var(--gutter-xlg);
    > * {
      grid-column: 4 / -4;
      position: relative;
      width: 100%;
      max-width: min(72rem);
      justify-self: center;
    }
    &:before {
      box-sizing: content-box;
      content: '';
      grid-column: 4 / -4;
      background-color: white;
      padding: 0 var(--gutter-lg);
      position: absolute;
      width: 100%;
      height: 100%;
      left: 50%;
      transform: translateX(-50%);
      z-index: 0;
    }
    h1 {
      margin: 1em 0 0;
    }
    h2 {
      font-size: var(--fs-24);
      ${allCaps};
      font-family: var(--sans-serif);
      font-weight: 700;
      margin: 2em 0 0.25em;
    }
    .lede {
      font-size: var(--fs-24);
      font-weight: 300;
      p {
        line-height: 1.5;
      }
    }
    p {
      line-height: 2;
      + p {
        margin-top: 0;
      }
    }
    ${mq().m} {
      > *,
      &:before {
        grid-column: 3 / -3;
      }
    }
    ${mq().s} {
      > * {
        grid-column: 2 / -2;
        padding: 0 var(--gutter-md);
        box-sizing: border-box;
      }
      &:before {
        grid-column: 2 / -2;
        padding: 0 0.5rem;
      }
    }
  `
  const figureStyle = css`
    div > & {
      grid-column: 2 / span 9;
      max-width: 100%;
      margin: var(--gutter-md) 0 var(--gutter-sm);
      display: flex;
      > div {
        width: 100%;
      }
      img {
        min-height: 100%;
        min-width: 100%;
      }
      ${mq().s} {
        grid-column: 1 / span 13;
        padding: 0 0.5rem 0 0;
      }
    }
  `
  const figCaptionStyle = css`
    div > & {
      justify-self: flex-start;
      font-size: var(--fs-16);
      font-style: italic;
      line-height: 1.5;
      grid-column: 11 / -4;
      width: calc(100% + var(--gutter-lg) - var(--gutter-sm));
      transform: translateX(calc(-1 * var(--gutter-sm)));
      margin: var(--gutter-md) 0 var(--gutter-sm);
      ${mq().m} {
        grid-column-end: -3;
        transform: translateX(calc(-0.5 * var(--gutter-sm)));
      }
      ${mq().s} {
        grid-column: 2 / span 11;
        margin-top: 0;
      }
    }
  `
  return (
    <Layout mainCss={{ paddingTop: 'var(--nav-menu-height)' }}>
      <Seo
        title={page.seo.title || page.heading}
        description={page.seo.description}
      />
      <div css={mainStyle}>
        <HeroImage
          image={page.heroImage}
          wrapperCss={heroImageStyle}
          innerCss={heroImageInnerStyle}
        />
        <div css={bodyStyle}>
          <h1>{page.heading}</h1>
          <div
            className="lede"
            dangerouslySetInnerHTML={{
              __html: page.ledeNode.childMarkdownRemark.html,
            }}
          />
          <StructuredText
            data={page.body}
            renderBlock={({ record }) => {
              switch (record.__typename) {
                case 'DatoCmsArticleImage':
                  return (
                    <>
                      <figure css={figureStyle}>
                        <GatsbyImage
                          image={record.image.gatsbyImageData}
                          alt={record.image.alt || ''}
                          title={record.image.title}
                        />
                      </figure>
                      <figcaption css={figCaptionStyle}>
                        {record.image.title}
                      </figcaption>
                    </>
                  )
                default:
                  return null
              }
            }}
          />
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage
